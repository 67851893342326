// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  CURRENT_MODE: 'dev',

  BASE_URLS: {
    //prod
    //  BUSINESS: 'http://54.254.25.252:5000/nmra-medicine-database',
    //stg
    // BUSINESS: 'http://13.229.57.73:5000/nmra-medicine-database',
    BUSINESS: 'https://drug-stage.nmra.lk/nmra-medicine-database',
    AUTH_SERVER: '',
  },

  ACCRESS_DETAILS: {
    TOKEN: '',
    ROMOVE_TOKEN: '',
    CLIENT_DETAILS: '',
  },

  AUTHENTICATION_DETAILS: {
    ACCESS_TOKEN_NAME: 'accessToken',
    REFRESH_TOKEN: 'refreshToken',
    TOKEN_TYPE: 'tokenType',
  },

  LOGGED_IN_USER_DETAILS: {
    LOGGED_IN_USERNAME: 'loggedInUsername',
    LOGGED_IN_USER_ID: 'loggedInUserId',
    LOGGED_IN_USER_DISPLAY_NAME: 'loggedInUserDisplayName',
    LOGGED_IN_USER_DESIGNATION: 'loggedInUserDesignation',
    LOGGED_IN_USER_ROLE_ID: 'loggedInUserRoleId',
    LOGGED_IN_USER_ROLE_NAME: 'loggedInUserRoleName',
    LOGGED_IN_USER_DIVISIONAL_SECETARIAT: 'loggedInUserDS',
    LOGGED_IN_USER_DS_ID: 'loggedInUserDSID',
    LOGGED_IN_USER_PERMISSIONS: 'loggedInUserPermissions',
  },

  DEFAULT_API_VERSION: 'V1.0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
