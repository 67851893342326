import { Injectable } from '@angular/core';
import { LoggedInUser } from '@models/business/logged-in-user.model';
import ROLES from 'src/assets/auth/jwt-permission-configs.json';
import { JwtAuthService } from './jwt-auth.service';


@Injectable({
  providedIn: 'root'
})
export class JwtPermissionService {

  constructor(private authService: JwtAuthService) { }

  public isPermitted(code: string): boolean {
    const user = this.authService.fetchUser();

    if (!user || !user.role) { return false; }

    const roleList = ROLES;

    // console.log('Role list: ', roleList)

    let rolePermissions = ROLES.ROLES[user.role.toUpperCase()] as Array<string>;

    if (!rolePermissions) { rolePermissions = ["ROUTE_DASHBOARD"]; }

    return rolePermissions.some(p => p === code);
  }
}
